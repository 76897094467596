@import '../../assets/scss/variables';


.iccid-search-section{
  padding: 20px;
  min-height: calc(100vh - 75px);
  display: flex;
  align-items: center;
  justify-content: center;
  .search-block{
    width: 100%;
    max-width: 700px;
    h2{
      margin: 0px 0px 12px;
      font-size: 30px;
      font-weight: 700;
      line-height: 120%;
    }
    .p-inputtext-lg{
      border-radius: 6px;
      border: 1px solid #D9D9D9;
      background: #FFF;
      font-size: 16px;
      font-weight: 400;
      line-height: 120%;
      color: $black;
      &::placeholder{
        color: #939393;
        font-weight: 400;
      }
    }
    .p-inputgroup-addon{
      background: transparent;
      border: none;
      position: relative;
      right: 3.5rem;
      z-index: 99;
    }
  }
}
.admin-userlist-section{
  position: relative;
  .progress-spinner{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
      .progressSpinner{
          width: 60px;
          height: 60px;
      }
  }
}
.esim-details-section {
  padding: 20px;
  min-height: calc(100vh - 75px);
  h2{
    margin: 0px;
    font-weight: 600;
    font-size: 24px;
    color: #161b28;
  }
  .users-data-table{
    margin: 20px 0px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.02);
    border-radius: 10px;
    padding: 25px 25px 15px;
    .iccid-section{
      display: flex;
      align-items: center;
      justify-content: space-between;
      h3{
        margin: 0px;
        font-size: 18px;
        span{
          border-bottom: 1px solid;
        }
      }
      .copy-btn{
        padding: 0px;
        background: none;
        width: 2.5rem;
        .p-button-icon{
          font-size: 18px;
          color: $black;
          border: none;
          &:hover{
            color: $secondary;
          }
        }
      }
      .status-section{
        display: flex;
        align-items: center;
        gap: 1.5rem;
        p{
          font-size: 16px;
          margin: 0px;
        }
        .status-badge{
          display: inline-block;
          padding: 5px 10px;
          border-radius: 3px;
          font-size: 12px;
          margin-left: 5px;
          &.completed{
            color: #67b173;
            background-color: rgba(103, 177, 115, 0.15);
          }
          &.released{
            color: #67b173;
            background-color: rgba(103, 177, 115, 0.15);
          }
        }
      }
    }
  }
}

.esim-details-accordion{
  margin-top: 2rem;
  .p-accordion-tab{
    margin-bottom: 15px;
    .p-accordion-header{
      .p-accordion-header-link{
        padding: 0.5rem 1.25rem;
        border-radius: 8px;
        border: 1px solid #DCDCDC;
        background: #F4F6FE;
        .p-accordion-toggle-icon{
          position: absolute;
          right: 1rem;
          font-size: 12px;
        }
        &:focus{
          box-shadow: none !important;
        }
      }
    }
  }
  .p-datatable{
    .p-datatable-wrapper{
        border-radius: 10px 10px;
        overflow: auto;
        .p-datatable-table{
            .p-datatable-thead{
                tr{
                    background: $theme-gray;
                    th{
                        background: $theme-gray;
                        border-width: 0 0 0px 0;
                        padding: 11px 1rem;
                        .p-column-title{
                            font-weight: 600;
                            font-size: 14px;
                            color: $white;
                            .bundle-refresh{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .pi-refresh{
                                    background: $secondary;
                                    padding: 5px;
                                    font-size: 13px;
                                    cursor: pointer;
                                }
                            }
                        }
                        .p-sortable-column-icon{
                            font-size: 12px;
                            color: $white;
                        }
                        &.p-highlight{
                            background: $primary;
                            border-width: 0 0 0px 0;
                            border-radius: 5px;
                            padding: 12px 10px;
                            margin: 5px;
                            display: block;
                            .p-column-title{
                                color: $theme-gray;
                            }
                            .p-sortable-column-icon{
                                color: $theme-gray;
                            }
                        }
                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
            }
            .p-datatable-tbody{
                tr{
                  background: none;
                    td{
                        font-weight: 400;
                        font-size: 14px;
                        border-width:1px;
                        color: $black;
                        padding: 0.7rem 1rem;
                        word-wrap: break-word;
                        word-break: break-all;
                        p{
                            margin: 0px;
                        }
                        .status{
                            background: #FAFBFF;
                            border-radius: 5px;
                            font-weight: 400;
                            font-size: 12px;
                            padding: 6px 15px;
                            width: 86px;
                            display: inline-block;
                            text-align: center;
                            &.completed{
                                color: #67b173;
                                background-color: rgba(103,177,115,0.15);
                            }
                            &.pending{
                                color: #efb226;
                                background-color: rgba(255,200,75,0.15);
                            }
                            &.failed{
                                color: #f17171;
                                background-color: rgba(241,113,113,0.15);
                            }

                            &.used {
                                color: #888;
                                background-color: #dcdcdc;
                            }
                        }
                        .dot{
                            width: 10px;
                            height: 10px;
                            border-radius: 20px;
                            background: #DEE2FF;
                            display: inline-block;
                            margin-right: 6px;
                            &.active{
                                background: #29B026;
                            }
                            &.inactive{
                                background: #DB3232;
                            }
                        }
                        .view-order{
                            background: $theme-gray;
                            border: 1px solid rgba(231, 235, 255, 0.59);
                            border-radius: 5px;
                            font-weight: 400;
                            font-size: 10px;
                            text-transform: uppercase;
                            color: $white;
                            padding: 5px 10px;
                            &:hover{
                                background: #FAFBFF;
                                color: $black;
                            }
                            .p-button-icon{
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    .p-paginator{
        border-radius: 0px 0px 10px 10px;
        border: none;
        .p-paginator-current{
            font-weight: 400;
            font-size: 13px;
            color: $black;
        }
        .p-paginator-pages{
            .p-paginator-page{
                min-width: 25px;
                height: 25px;
                font-size: 13px;
            }
        }
        .p-dropdown{
            height: auto;
            border: 1px solid #E7EBFF;
            .p-dropdown-label{
                font-size: 12px;
                padding: 8px 0px 8px 8px;
            }
        }
        .p-dropdown-trigger{
            .p-dropdown-trigger-icon{
                font-size: 12px;
            }
        }
    }
  }
  .details-form{
    border-radius: 8px;
    border: 1px solid #DCDCDC;
    background: #FFF;
    padding: 20px;
    .top-block{
      display: flex;
      align-items: center;
      justify-content: space-between;
      div{
        font-size: 14px;
        font-weight: 400;
        line-height: 160%;
        strong{
          display: block;
          margin-top: 2px;
          color: #3E3F6C;
          font-size: 16px;
          font-weight: 600;
          line-height: 160%;
        }
      }
      .refresh-button{
        border: none;
        background:$theme-gray;
        padding: 12px 20px;
        border-radius: 5px;
        .p-button-label{
            font-size: 13px;
            font-weight: 600;
        }
        .p-button-icon{
            font-size: 13px;
        }
        &:hover{
            background: $secondary;
        }
      }
    }
    .bottom-block{
        margin-top: 1.5rem;
        @extend .top-block;
        gap: 50px;
        justify-content: flex-start;
        label{
          display: block;
          margin-bottom: 2px;
        }
        .message-textarea{
          border-radius: 8px;
          border: 1px solid #DCDCDC;
          background: #FFF;
          padding: 10px 8px 9px 12px;
          resize: none;
          color: $black;
          font-size: 14px;
          width: 500px;
          &::placeholder{
            color: #777;
            font-size: 12px;
          }
        }
        .send-button{
          border: none;
          background:$theme-gray;
          padding: 7px 25px;
          border-radius: 5px;
          position: absolute;
          bottom: 1rem;
          right: 0.5rem;
          .p-button-label{
              font-size: 12px;
              font-weight: 600;
          }
          &:hover{
              background: $secondary;
          }
        }
    }
  }
  .p-image {
    line-height: 0px;
  }
}
.custom-tooltip{
  width: 100%;
  max-width: 230px;
  .p-tooltip-text{
    font-size: 12px;
    text-align: center;
    padding: 8px;
  }
}
.esim-search-field{
  width: 100%;
  max-width: 350px; 
  .p-inputtext{
    border-radius: 6px;
    border: 1px solid #D9D9D9;
    background: #FFF;
    width: 100%;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    &::placeholder{
      color: #939393;
      font-weight: 400;
    }
  }
  &.usereSIM-details{
    position: absolute;
    z-index: 99;
  }
  .pi-search{
    right: 0.75rem;
  }
}
.switch-toggle{
  display: flex;
  align-items: center;
  gap:10px;
  span{
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
  }
  .p-inputswitch{
    width: 30px;
    height: 16px;
    .p-inputswitch-slider{
      background: #D9D9D9;
        &::before{
            width: 12px;
            height: 12px;
            left: 2px;
            margin-top: -6px;
            background: #3E3F6C;
        }
        &:focus{
          box-shadow: none;
        }
    }
    &.p-highlight{
        .p-inputswitch-slider{
            background: #00AC65;
            &::before{
                transform: translateX(15px);
                left: 0px;
                background: $white;
            }
        }
    }
  }
}
.esim-actions{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

// Responsive CSS

@media (max-width:768px){
  .iccid-search-section{
      min-height: calc(100vh - 150px);
      align-items: flex-start;
      .search-block h2{
        font-size: 16px;
      }
  }
  .esims-tabs{
    padding-top: 4rem;
    .p-tabview-nav{
      justify-content: center;
    }
  }
  .esim-search-field.usereSIM-details{
      left: 0px;
      right: 0px;
      margin: 0 auto;
  }
  .esim-details-section{
    .mobile-flex{
      flex-direction: column;
      align-items: flex-start !important;
    }
    .esim-search-field{
      max-width: 100%;
      margin-top: 1rem;
    }
    .users-data-table{
      padding: 15px;
      .iccid-section{
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }
    }
  }
  .esim-details-accordion{
    .details-form{
      padding: 15px;
      .top-block{
        flex-direction: column;
        align-items: flex-start;
        div{
          display: flex;
          width: 100%;
          margin: 2px 0px;
          span{
            flex: 1 0 50%;
            position: relative;
            font-weight: 600;
            &:after {
              content: ':';
              position: absolute;
              right: 15px;
              font-size: 14px;
              font-weight: 500;
            }
          }
          strong{
            flex: 1 0 50%;
            font-size: 14px;
            line-height: 125%;
            word-break: break-all;
          }
        }
        .refresh-button{
          display: block;
          span{
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
  .associated-bundles-table{
    .p-datatable-tbody{
      tr{
        border: 1px solid #d3d3d3;
        margin: 12px 0px;
        display: block;
        border-radius: 10px;
        td{
          text-align: left;
          padding: 0.5rem 1rem 0.5rem 47% !important;
          border: none;
          .p-column-title{
            position: absolute;
            left: 15px;
            width: 36%;
            &:after {
                content: ':';
                position: absolute;
                left: 100%;
                font-size: 14px;
                font-weight: 500;
            }
          }
        }
        &.p-datatable-emptymessage{
          td{
            padding: 0.5rem 1rem 0.5rem 1rem !important;
          }
        }
      }
    }
    .p-datatable-wrapper{
      .p-datatable-thead{
          display: none;
      }
      .p-datatable-tbody{
          tr{
              display: grid;
              td{
                  width: 100% !important;
                  position: relative;
                  &:nth-of-type(1):before { content: "Provider Name"; }
                  &:nth-of-type(2):before { content: "Bundle Name"; }
                  &:nth-of-type(3):before { content: "Plan"; }
                  &:nth-of-type(4):before { content: "Bundle Usage"; }
                  &:nth-of-type(5):before { content: "Purchased On"; }
                  &:nth-of-type(6):before { content: "Expiry On"; }
                  &:nth-of-type(7):before { content: "Price"; }
                  &:before {
                    position: absolute;
                    left: 10px;
                    width: 50%;
                    padding-right: 10px;
                    white-space: nowrap;
                    font-size: 13px;
                    font-weight: 600;
                }
                &:after {
                    content: ':';
                    position: absolute;
                    left: 40%;
                    font-size: 14px;
                    font-weight: 600;
                    top: 30%;
                }
              }
          }
      }
    }
  }
  .switch-toggle span{
    font-size: 14px;
  }
  .esim-details-datatable{
    .p-datatable-wrapper{
      overflow-x: inherit !important;
      .p-datatable-thead{
        display: none;
      }
      .p-datatable-tbody{
        height: auto !important;
        overflow: inherit;
        padding: 10px;
          tr{
            background: #F5F5F5;
            display: inline-grid;
            width: 100%;
            margin: 5px 0px;
            border-radius: 10px;
            border-bottom: none;
            td{
                border: 1px solid #efecec;
                position: relative;
                padding-left: 50% !important;
                width: 100% !important;
                text-align: left;
                .p-column-title{
                    display: none;
                }
                &:before {
                    position: absolute;
                    left: 10px;
                    padding-right: 10px;
                    white-space: nowrap;
                    top: auto;
                    font-weight: 600;
                }
                width: 100% !important;
                &:nth-of-type(1):before { content: "ICCID"; }
                &:nth-of-type(2):before { content: "Customer"; }
                &:nth-of-type(3):before { content: "Bundles details"; }
                &:nth-of-type(4):before { content: "Validity"; }
                &:nth-of-type(5):before { content: "Provider Name"; }
                &:nth-of-type(6):before { content: "Date & Time"; }
                &:nth-of-type(7):before { content: "Status"; }
                &:nth-of-type(8):before { content: "Actions"; }
                &::after{
                    top: 30%;
                }
                &:after {
                    content: ':';
                    position: absolute;
                    left: 42%;
                    font-size: 14px;
                    font-weight: 600;
                    top: 30%;
                }
                &.last-column{
                  border-bottom: none;
                  .flex{
                    justify-content:flex-start !important;
                  }
                }
                .expiry-column{
                  width: 100%;
                  white-space: break-spaces;
                  max-width: 85%;
                  text-align: left;
                }
            }
            &.p-datatable-emptymessage{
              background: none;
              td{
                padding: 0.7rem 1rem !important;
                &::after, &::before{
                  display: none;
                }
              }
            }
          }
          .promocode-column{
            &::before{
              background: none;
            }
            &::after{
              background: none;
            }
          }
      }
    }
    .progress-bar{
      max-width: 100% !important;
    }
    .p-paginator{
      flex-wrap: wrap;
      padding: 0.5rem 0rem;
      .p-link{
        min-width:2rem;
        height: 2rem;
      }
      .p-dropdown .p-dropdown-trigger{
        width: 2rem;
      }
    }
    .p-datatable-tbody tr td{
      .p-column-title{
        position: absolute;
        left: 15px;
      }
      .status{
        margin: 0px;
      }
    }
  }
  .esim-actions{
    justify-content: flex-start;
  }
}

@media (min-width:769px) and (max-width:960px){
  .esim-search-field{
    width: auto;
  }
  .esim-details-datatable{
    .p-datatable-wrapper{
      overflow-x: inherit !important;
      .p-datatable-thead{
        display: none;
      }
      .p-datatable-tbody{
        height: auto !important;
        overflow: inherit;
        padding: 10px;
          tr{
            background: #F5F5F5;
            display: inline-grid;
            width: 100%;
            margin: 5px 0px;
            border-radius: 10px;
            border-bottom: none;
            td{
                border: 1px solid #efecec;
                position: relative;
                padding-left: 50% !important;
                width: 100% !important;
                text-align: left;
                .p-column-title{
                    display: none;
                }
                &:before {
                    position: absolute;
                    left: 10px;
                    padding-right: 10px;
                    white-space: nowrap;
                    top: auto;
                    font-weight: 600;
                }
                width: 100% !important;
                &:nth-of-type(1):before { content: "ICCID"; }
                &:nth-of-type(2):before { content: "Customer"; }
                &:nth-of-type(3):before { content: "Bundles details"; }
                &:nth-of-type(4):before { content: "Validity"; }
                &:nth-of-type(5):before { content: "Provider Name"; }
                &:nth-of-type(6):before { content: "Date & Time"; }
                &:nth-of-type(7):before { content: "Status"; }
                &:nth-of-type(8):before { content: "Actions"; }
                &::after{
                    top: 30%;
                }
                &:after {
                    content: ':';
                    position: absolute;
                    left: 42%;
                    font-size: 14px;
                    font-weight: 600;
                    top: 30%;
                }
                &.last-column{
                  border-bottom: none;
                  .flex{
                    justify-content:flex-start !important;
                  }
                }
                .expiry-column{
                  width: 100%;
                  white-space: break-spaces;
                  max-width: 85%;
                  text-align: left;
                }
            }
            &.p-datatable-emptymessage{
              background: none;
              td{
                padding: 0.7rem 1rem !important;
                &::after, &::before{
                  display: none;
                }
              }
            }
          }
          .promocode-column{
            &::before{
              background: none;
            }
            &::after{
              background: none;
            }
          }
      }
    }
    .progress-bar{
      max-width: 100% !important;
    }
    .p-paginator{
      flex-wrap: wrap;
      padding: 0.5rem 0rem;
      .p-link{
        min-width:2rem;
        height: 2rem;
      }
      .p-dropdown .p-dropdown-trigger{
        width: 2rem;
      }
    }
    .p-datatable-tbody tr td{
      .p-column-title{
        position: absolute;
        left: 15px;
      }
      .status{
        margin: 0px;
      }
    }
  }
  .esim-details-section{
    .mobile-flex{
      flex-direction: column;
      align-items: flex-start !important;
    }
    .esim-search-field{
      max-width: 100%;
      margin-top: 1rem;
    }
    .users-data-table{
      padding: 15px;
      .iccid-section{
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }
    }
  }
  .esim-details-accordion{
    .details-form{
      padding: 15px;
      .top-block{
        flex-direction: column;
        align-items: flex-start;
        div{
          display: flex;
          width: 100%;
          margin: 2px 0px;
          span{
            flex: 1 0 50%;
            position: relative;
            font-weight: 600;
            &:after {
              content: ':';
              position: absolute;
              right: 15px;
              font-size: 14px;
              font-weight: 500;
            }
          }
          strong{
            flex: 1 0 50%;
            font-size: 14px;
            line-height: 125%;
            word-break: break-all;
          }
        }
        .refresh-button{
          display: block;
          span{
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
  .associated-bundles-table{
    .p-datatable-tbody{
      tr{
        border: 1px solid #d3d3d3;
        margin: 12px 0px;
        display: block;
        border-radius: 10px;
        td{
          text-align: left;
          padding: 0.5rem 1rem 0.5rem 47% !important;
          border: none;
          .p-column-title{
            position: absolute;
            left: 15px;
            width: 36%;
            &:after {
                content: ':';
                position: absolute;
                left: 100%;
                font-size: 14px;
                font-weight: 500;
            }
          }
        }
        &.p-datatable-emptymessage{
          td{
            padding: 0.5rem 1rem 0.5rem 1rem !important;
          }
        }
      }
    }
    .p-datatable-wrapper{
      .p-datatable-thead{
          display: none;
      }
      .p-datatable-tbody{
          tr{
              display: grid;
              td{
                  width: 100% !important;
                  position: relative;
                  &:nth-of-type(1):before { content: "Provider Name"; }
                  &:nth-of-type(2):before { content: "Bundle Name"; }
                  &:nth-of-type(3):before { content: "Plan"; }
                  &:nth-of-type(4):before { content: "Bundle Usage"; }
                  &:nth-of-type(5):before { content: "Purchased On"; }
                  &:nth-of-type(6):before { content: "Expiry On"; }
                  &:nth-of-type(7):before { content: "Price"; }
                  &:before {
                    position: absolute;
                    left: 10px;
                    width: 50%;
                    padding-right: 10px;
                    white-space: nowrap;
                    font-size: 13px;
                    font-weight: 600;
                }
                &:after {
                    content: ':';
                    position: absolute;
                    left: 40%;
                    font-size: 14px;
                    font-weight: 600;
                    top: 30%;
                }
              }
          }
      }
    }
  }
  .esim-actions{
    justify-content: flex-start;
  }
}