@import '../../assets/scss/variables';

.heading-sec{
    .p-dropdown{
        width: 225px;
        text-align: left;
        height: 35px;
        border: 1px solid $blue-light;
        position: relative;
        top: 2px;
        .p-dropdown-label{
            font-family: $font-base;
            font-size: 14px;
            color: $black;
            padding:9px 12px;
            &.p-placeholder{
                color: #000;
                font-weight: 600; 
            }
        }
        .p-dropdown-trigger{
            .pi{
                font-size: 12px;
            }
        }
    }
}
.users-data-table{
    .p-inputnumber{
        .p-inputtext{
            height: 30px;
            width: 90px;
            background: #FAFBFF;
            border-radius: 5px;
            border: 1px solid #DEE2FF;
            font-size: 14px;
            font-weight: 500;
            padding: 12px 15px 12px 20px;
        }
    }
    .p-row-editor-init{
        background: #F3F6FF !important;
        .p-row-editor-init-icon{
            &::before{
                font-size: 14px;
            }
        }
        &:hover{
            background: #DEE2FF !important;
        }
    }
    .p-row-editor-save{
        background: $primary !important;
        .p-row-editor-save-icon{
            color: $white;
            &::before{
                font-size: 14px;
            }
        }
    }
    .p-row-editor-cancel{
        &:hover{
            background: $error-red !important;
            .p-row-editor-cancel-icon{
                color: $white;
            }
        }
    }
    button{
        &:focus{
            box-shadow:none !important;
        }
    }
    .action-btn{
        width: 32px;
        height: 32px;
        border-radius: 30px;
        margin-right: 8px;
        border: none;
        .p-button-icon{
            font-size: 14px;
        }
        &.edit{
            background: #F3F6FF;
            color: #6c757d;
            &:hover{
                background: #DEE2FF;
            }
        }
        &.check{
            background: $theme-gray;
            color: $white;
            &:hover{
                opacity: 0.8;
            }
        }
        &.close{
            background: transparent;
            color: $theme-gray;
            &:hover{
                background: $error-red;
                .p-button-icon{
                    color: $white;
                }
            }
        }
    }
    &.price-adjustment{
        .p-datatable-table{
            .p-datatable-tbody{
                tr{
                    td{
                        padding: 0.8rem 1rem;
                        border-width: 0 0 0px 0 !important;
                    }
                    &.p-row-odd{
                        background: #f5f5f5;
                    }
                }
            }
        }
        .p-datatable .p-paginator-bottom{
            border-top: 1px solid #E7EBFF;
        }
    }
}
.p-dropdown-panel{
    .p-dropdown-header{
        .p-dropdown-filter-container{
            .p-dropdown-filter{
                height: 40px;
            }
        }
    }
}

.priceError {
    color: red;
    margin-top: 5px;
    font-size: 12px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #495057;
    background: #fff;
}
.currencyVal{
    position: absolute;
    margin:6px 4px 6px 10px;
    color: #495057;
    font-size: 14px;
}
.admin-users-section{
    .heading-sec{
        .file-upload{
            position: relative;
            .file-upload-input{
                padding: 2px;
                border: 1px solid #f3f3f3;
                text-align: left;
                padding-left: 10px;
                line-height: 40px;
                min-width: 360px;
                &::file-selector-button{
                    display: none;
                }
                &::-webkit-file-upload-button{
                    float: right;
                    margin-right:0;
                }
                &::-mz-file-upload-button{
                    float: right;
                    margin-right:0;
                }
            }
            .placeholder-text {
                font-weight: bold;
            }
            .upload-button{
                border: none;
                background:$theme-gray;
                padding: 12px 20px;
                border-radius: 5px;
                position: absolute;
                right: 2px;
                top: 2px;
                .p-button-label{
                    font-size: 15px;
                    font-weight: 600;
                }
                .p-button-icon{
                    font-size: 13px;
                }
                &:hover{
                    background: $secondary;
                }
            }
        }
        .export-button{
            border: none;
            background:$theme-gray;
            padding: 12px 20px;
            border-radius: 5px;
            .p-button-label{
                font-size: 15px;
                font-weight: 600;
            }
            .p-button-icon{
                font-size: 13px;
            }
            &:hover{
                background: $secondary;
            }
        }
    }
    .plan-selection{
        .p-datatable{
            .p-datatable-wrapper{
                .p-datatable-table{
                    .p-datatable-thead{
                        tr{
                            th{
                                &.last-column{
                                    .p-column-header-content{
                                        justify-content: center;
                                    }
                                }
                            }
                        }
                    }
                    .p-datatable-tbody{
                        tr{
                            &.p-row-odd{
                                background: #f5f5f5;
                            }
                            td{
                                padding: 0.45rem 1rem;
                                .last-price-input{
                                    .p-inputtext{
                                        width: 85px;
                                        height: 38px;
                                        border-radius: 5px;
                                        border: 1px solid #EDEDED;
                                        font-size: 14px;
                                    }
                                }
                                .plan-input{
                                    .p-inputtext{
                                        width: 86%;
                                        height: 38px;
                                        border-radius: 5px;
                                        border: 1px solid #EDEDED;
                                        font-size: 14px;
                                    }
                                }
                                &.last-column{
                                    .p-checkbox{
                                        .p-checkbox-box{
                                            width: 24px;
                                            min-width: 24px;
                                            height: 24px;
                                            border-radius: 5px;
                                            border: 1px solid #C4C4C4;
                                            &.p-highlight{
                                                background: transparent;
                                            }
                                            .p-checkbox-icon{
                                                color: $grey-dark;
                                                font-weight: 600;
                                            }
                                            &.p-focus{
                                                box-shadow: none;
                                            }
                                        }
                                        &.p-highlight{
                                            .p-checkbox-box{
                                                background: $white;
                                            }
                                        }
                                    }
                                }
                            }
                            &.row-edited {
                                border: 1px solid $secondary;
                            }
                            &.row-disabled{
                                opacity: 0.4;
                            }
                            &.row-disabled .offered-column {
                                opacity: 1 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
.buttons-sections {
    margin-top: 2rem;
    .confirm-button{
        padding: 14px 23px;
        border-radius: 6px;
        background:$theme-gray;
        border: none;
        .p-button-label{
            font-size: 15px;
            font-weight: 600;
        }
        &:hover{
            background: $secondary;
        }
        &.reset-btn{
            background: #D9D9D9;
            cursor: pointer;
            .p-button-label{
                color: $black;
            }
            &:hover{
                background: $secondary;
                .p-button-label{
                    color: $white;
                }
            }
        }
        &.min-width{
            min-width: 123px;
        }
        &.promo-create-button{
            width: 100%;
        }
    }
}
.filter-dialog{
    width: 95%;
    max-width: 1200px;
    .p-dialog-header{
        border-radius: 10px 10px 0px 0px;
        padding: 2rem;
        background: #F4F6FE;
        .p-dialog-header-icon{
            position: absolute;
            right: 7px;
            top: 7px;
            .p-dialog-header-close-icon{
                font-size: 13px;
            }
            &:focus{
                box-shadow: none;
            }
        }
    }
   .p-dialog-content{
        border-radius: 0px 0px 10px 10px;
        padding: 1rem 2rem 2rem;
        background: #F4F6FE;
        &:last-of-type{
            border-radius: 0px 0px 10px 10px;
        }
        .amount-data-options{
            gap: 18px 30px !important;
            label{
                min-width: 50px;
            }
        }
        .search-button{
            min-width: 95px;
            border-radius: 8px;
            border: 1px solid $secondary;
            background: $white;
            .p-button-label{
                color: $secondary;
            }
            &:hover{
                background: $secondary;
                .p-button-label{
                    color: $white;
                }
            }
            &.reset-btn{
                background: #D9D9D9;
                border: 1px solid #D9D9D9;
                .p-button-label{
                    color: $black;
                }
                &:hover{
                    background: $secondary;
                    .p-button-label{
                        color: $white;
                    }
                }
            }
        }
        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #D9D9D9;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: $theme-gray;
            padding: 2px;
            border-radius: 10px;
        }
        .p-dropdown{
            .p-dropdown-trigger{
                width: 2rem;
                .p-icon{
                    width: 12px;
                }
            }
            .p-dropdown-label{
                font-size: 15px;
            }
        }
        .p-multiselect{
            .p-multiselect-trigger{
                width: 2rem;
                .p-icon{
                    width: 12px;
                }
            }
            .p-multiselect-label{
                font-size: 15px;
                &.p-placeholder{
                    font-weight: 500;
                }
            }
        }
        .select-bundle-datatable{
            .p-datatable-table{
                .p-datatable-thead{
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                }
                .p-datatable-tbody{
                    display: block;
                    min-height: auto;
                    overflow: auto;
                    tr{
                        display: table;
                        width: 100%;
                        table-layout: fixed;
                    }
                    &::-webkit-scrollbar {
                        width: 4px;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #fff;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #CBCBCB;
                        padding: 2px;
                        border-radius: 10px;
                    }
                }
                &.highlight-tbody{
                    .p-datatable-tbody{
                        height: auto;
                    }
                }
            }
        }
        .select-bundle-datatable1{
            @extend .select-bundle-datatable;
            .p-datatable-table{
                &.highlight-tbody{
                    .p-datatable-tbody{
                        height: 432px;
                    }
                }
            }
        }
        .bundle-close-button{
            padding: 0px;
            border: none;
            background: none;
            width: auto;
            .p-button-icon{
                color: $secondary;
                font-size: 14px;
            }
            &:focus{
                box-shadow: none;
            }
            &:hover{
                opacity: 0.6;
            }
        }
        .btns-block{
            display: flex;
            gap: 0.5rem;
        }
   }
    .p-dropdown{
        width: 195px;
        text-align: left;
        height: 35px;
        border: 1px solid $blue-light;
        position: relative;
        top: 2px;
        .p-dropdown-label{
            font-family: $font-base;
            font-size: 12px;
            color: $black;
            padding:9px 12px;
        }
        .p-dropdown-trigger{
            .pi{
                font-size: 12px;
            }
        }
    }
    .p-checkbox{
        .p-checkbox-box{
            width: 17px;
            min-width: 17px;
            height: 17px;
            border-radius:3px;
            border: 1px solid #C4C4C4;
            &.p-highlight{
                background: transparent;
                &:hover{
                    background: none;
                }
            }
            .p-icon{
                color: $grey-dark;
                width: 12px;
            }
            &.p-focus{
                box-shadow: none;
            }
        }
        &.p-highlight{
            .p-checkbox-box{
                background: $white;
            }
        }
    }
    label{
        font-size: 13px;
    }
    p{
        font-size: 14px;
        font-weight: 700;
    }
    &.order-filters-dialog{
        max-width: 750px;
       .p-dialog-header{
            background: $white;
            padding: 2rem 2rem 0.5rem;
            .p-dialog-title{
                color: #3E4768;
                font-size: 26px;
                font-weight: 700;
                line-height: 120%;
            }
       }
       .p-dialog-content{
            background: $white;
            .title-label{
                color: #000;
                font-size: 16px;
                font-weight: 600;
                line-height: 120%;
            }
            .p-inputtext{
                border-radius: 8px;
                border: 1px solid #DDD;
                background: #FFF;
                height: 45px;
                &::placeholder{
                    color: #949494;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 120%;
                }
                &:focus{
                    box-shadow: none;
                }
            }
            .p-dropdown{
                width: 100%;
                height: 45px;
                border: 1px solid #DDD;
                border-radius: 8px;
                .p-inputtext{
                    border: none;
                    height: auto;
                    padding: 12px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 120%;
                    &.p-placeholder{
                        color: #949494;
                    }
                }
                &.p-focus{
                    box-shadow: none;
                }
            }
            .p-calendar{
                position: relative;
                .p-datepicker-trigger{
                    background: none;
                    border: none;
                    width: auto;
                    padding: 0;
                    position: absolute;
                    right: 15px;
                    top: 12px;
                    .p-icon{
                        color:#6B6B6B;
                    }
                    &:focus{
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
.p-dropdown-panel{
    .p-inputtext{
        &:focus{
            box-shadow: none;
        }
    }
}
.price-adjustment{
    .p-datatable{
        .p-datatable-wrapper{
            .list-price{
                position: relative;
            }
            .p-datatable-table{
                .p-datatable-thead{
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                    tr{
                        th{
                            padding: 17px 1rem !important;
                            &.p-highlight{
                                margin: 5px 0px !important;
                                display: table-cell !important;
                                background: none !important;
                                border-radius: 0px !important;
                                padding: 5px !important;
                                .p-column-header-content{
                                    background: #c1deea;
                                    border-width: 0 0 0px 0;
                                    border-radius: 5px;
                                    padding: 12px 10px;
                                }
                            }
                        }
                    }
                }
                .p-datatable-tbody{
                    display: block;
                    min-height: 100%;
                    height: calc(100vh - 360px);
                    overflow: auto;
                    tr{
                        display: table;
                        width: 100%;
                        table-layout: fixed;
                    }
                    &::-webkit-scrollbar {
                        width: 4px;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-track {
                        background: #f3f6ff;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        background: #CBCBCB;
                        padding: 2px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}
.file-upload-section{
    position: relative;
    .file-upload-input{
        &.csv-file{
            line-height: 33px;
            padding: 5px 5px 5px 10px;
            &::file-selector-button {
                float: right;
                margin: 0px;
                opacity: 0;
            }
        }
    }
    .placeholder-text{
        font-weight: bold;
        position: absolute;
        right: 6px;
        top: 6px;
        background: $theme-gray;
        padding: 8px 10px;
        border-radius: 5px;
        color: $white;
        font-weight: 600;
        .pi{
            font-size: 14px;
            margin-right: 5px;
        }
    }
}
.bundle-delete-tooltip{
    .p-tooltip-text{
        font-size: 12px;
        padding: 6px 8px;
        border-radius: 5px;
    }
}
.data-amount-multiselect{
    width: 250px;
    .p-multiselect-filter-container{
        .p-inputtext{
            padding: 8px;
        }
    }
    .p-multiselect-items-wrapper{
        &::-webkit-scrollbar {
            width: 6px;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: #f3f6ff;
            border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
            background: #CBCBCB;
            padding: 2px;
            border-radius: 10px;
        }
    }
}
.select-bundle-pagination{
    padding: 0rem 1rem;
    border-radius: 0px 0px 10px 10px !important;
    border: none !important;
    .p-link{
        min-width: 1.6rem !important;
        height: 1.6rem !important;
        font-size: 13px;
        .p-paginator-icon{
            font-size: 14px;
        }
    }
    .p-paginator-current{
        font-size: 13px;
    }
}

// Responsive CSS

@media (max-width:768px){
    .price-adjust-section{
        .heading-sec{
            .mobile-flex{
                flex-direction: column;
                align-items: flex-start !important;
                .width-full{
                    width: 100%;
                }
                .right-section{
                    width: 100%;
                    margin-top: 1rem;
                    .file-upload-section{
                        .file-upload-input.csv-file{
                            width: 100%;
                            padding: 4px 5px 4px 10px;
                            font-size: 14px;
                        }
                        .placeholder-text{
                            font-size: 13px;
                        }
                    }
                }
            }
           .export-button{
                padding: 12px 5px !important;
                min-width: 100px;
                .p-button-icon{
                    margin-right: 0.15rem;
                }
                .p-button-label{
                    font-size: 13px;
                }
           }
           .filter-section{
                display: none !important;
                &.mobile-filter-section{
                    display: block !important;
                    .mobile-lex{
                        flex-direction: column;
                        .p-dropdown{
                            width: 100% !important;
                        }
                    }
                    .exportBtn{
                        margin-top: 1rem;
                        background: #c1deea;
                        .p-button-label, .p-button-icon{
                            color: $black;
                        }
                    }
                }
           }
        }
        .buttons-sections{
            margin-top: 0.6rem;
        }
    }
    .price-adjustment{
        .p-datatable{
            padding: 0px 10px;
            .p-datatable-wrapper{
                .p-datatable-table{
                    .p-datatable-thead{
                        display: none;
                    }
                    .p-datatable-tbody{
                        height: calc(100vh - 325px);
                        tr{
                            position: relative;
                            margin: 0px 0px;
                            background: #f9f9f9;
                            padding: 5px 0px;
                            border: 1px solid #e7e7e7;
                            border-radius: 10px;
                            margin: 10px 0px;
                            td{
                                padding-left: 42% !important;
                                border: none !important;
                                .p-column-title{
                                    position: absolute;
                                    left: 15px;
                                }
                                &:after {
                                    content: ':';
                                    position: absolute;
                                    left: 35%;
                                    font-size: 14px;
                                    font-weight: 600;
                                }
                            }
                            &.p-row-odd{
                                background: transparent !important;
                            }
                        }
                    }
                }
            }
        }
        
    }
    .filter-dialog{
        width: 90%;
        .p-dialog-content{
            padding: 1rem;
            .filter-content{
                .m-flex{
                    flex-direction: column;
                    align-items: flex-start !important;
                }
            }
        }
        .p-dialog-header{
            padding: 1rem !important;
        }
    }
}

@media (min-width:768px) and (max-width:960px){
    .price-adjust-section{
       .mobile-flex{
            flex-direction: column;
            .width-full{
                width: 100%;
            }
       }
       .right-section{
            margin-top: 1.2rem;
            width: 100%;
            justify-content: space-between;
       }
       .filter-section{
            display: none !important;
            &.mobile-filter-section{
                display: block !important;
            }
            .exportBtn{
                background: $black;
                margin-top: 1rem;
            }
        }
       .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody{
            height: calc(100vh - 300px);
       }
    }
}

@media (max-width:960px){
    .price-adjustment-table{
        .p-datatable-wrapper{
            .p-datatable-thead{
                display: none !important;
            }
            .p-datatable-tbody{
                padding: 0px 10px;
                tr{
                    display: grid !important;
                    margin: 10px 0px;
                    background: #f9f9f9;
                    padding: 5px 0px;
                    border: 1px solid #e7e7e7;
                    border-radius: 10px;
                    td{
                        border: 1px solid #efecec !important;
                        position: relative;
                        padding-left: 46% !important;
                        width: 100% !important;
                        .p-column-title{
                            display: none;
                        }
                        &:before {
                            position: absolute;
                            left: 10px;
                            width: 50%;
                            padding-right: 10px;
                            white-space: nowrap;
                            font-size: 13px;
                            font-weight: 600;
                        }
                        &:after {
                            content: ':';
                            position: absolute;
                            left: 40%;
                            font-size: 14px;
                            font-weight: 600;
                            top: 30%;
                        }
                        &:nth-of-type(1):before { content: "Provider"; }
                        &:nth-of-type(2):before { content: "Plan"; }
                        &:nth-of-type(3):before { content: "Bundle Name"; }
                        &:nth-of-type(4):before { content: "Data"; }
                        &:nth-of-type(5):before { content: "Duration"; }
                        &:nth-of-type(6):before { content: "Speed"; }
                        &:nth-of-type(7):before { content: "Buy Price"; }
                        &:nth-of-type(8):before { content: "List Price"; }
                        &:nth-of-type(9):before { content: "Offered"; }
                        &.last-column{
                            .justify-content-center {
                                justify-content: start !important;
                            }
                        }
                    }
                }
            }
        }
    }
}